<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <table-action-menu :index="index" :content="content" :action-menu-items="actionListMenu" />
</template>

<script>
import TableActionMenu from '@/components/shared/nerve-table/TableActionMenu.vue';
import Logger from '@/utils/logger';

export default {
  components: { TableActionMenu },
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    id: '',
  }),
  computed: {
    actionListMenu() {
      return [
        {
          name: 'retry',
          text: 'deployDetails.retryAll',
          // eslint-disable-next-line no-bitwise
          isAllowed:
            this.content.status === 'Completed' || this.content.status === 'In progress'
              ? false
              : this.canAccess('UI_DEPLOY:LOG_RESET_ALL'),
          action: async () => {
            try {
              await this.$store.dispatch('deploy-details/reset_all_tasks', this.id);
            } catch (e) {
              Logger.error(e);
            }
          },
        },
      ];
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      // eslint-disable-next-line prefer-destructuring
      this.id = window.location.pathname.split('/').reverse()[0];
    });
  },
};
</script>
